//删除教室的弹框
<template>
    <div class="del_class_room_dialog">
        <CommonDialog @close="close" :isCenter="isCenter" :showDialog="showDialog" :title="title" :width="width">
            <template v-slot:content>
                <div class="dialog_content">

                    <!-- 间隔线 -->
                    <el-divider></el-divider>

                    <!-- 提示信息部分 -->
                    <div class="msg_info">确定删除当前教室吗？</div>

                    <!-- 替换按钮  -->
                    <div class='com_dv'>
                        <el-button :loading="showLoading" :class="['com_bt_action','commit_bt','search_bt_active']" @click.stop="doSaveAction">确&nbsp;&nbsp;&nbsp;&nbsp;定</el-button>
                    </div>
                </div>
            </template>
        </CommonDialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            title: "删除教室",

            isCenter: false,

            width: "400px",

            //提交状态值
            showLoading: false,
        };
    },
    created() {},
    methods: {
        //关闭的方法
        close() {
            this.$emit("close");
        },

        //确定
        doSaveAction() {
            this.showLoading = true;
            let param = this.getHttpParams();
            param.type = 9;
            param.roomId = this.actionItem.id;
            this.$http
                .fetchPost(this.$api.LESSONMANAGER, param)
                .then((res) => {
                    let result = res.data;
                    if (result.state == 200) {
                        //操作成功,关闭
                        this.close();

                        this.showLoading = false;

                        //刷新页面数据
                        this.$emit("refreshPageData");
                    } else {
                        this.showLoading = false;
                        this.showWarnMsg(`${res.data.msg},不能进行删除操作`);
                    }
                })
                .catch((err) => {
                    this.showLoading = false;
                    this.showErrorMsg("发生异常，操作失败");
                });
        },
    },
    computed: {},
    components: {},
    props: ["showDialog", "actionItem"],
};
</script>
<style lang="scss" scoped>
.del_class_room_dialog {
    .dialog_content {
        position: relative;
        min-height: 200px;

        .com_dv {
            width: 100%;
            align-items: center;
            justify-content: center;
            display: flex;

            .search_bt_active {
                background: #409eff;
                color: white;
                cursor: pointer;
            }

            .search_bt_normal {
                background: #cfd1d4;
                color: white;
                cursor: not-allowed;
            }

            .commit_bt {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                width: 120px;
                position: absolute;
                bottom: 14px;
                left: 50%;
                transform: translateX(-50%);
                border: none;
            }
        }

        .msg_info {
            margin-top: 60px;
            display: flex;
            font-weight: bold;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>
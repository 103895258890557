//新增教室的弹框
<template>
    <div class="add_class_room_dialog">
        <CommonDialog @close="close" :isCenter="isCenter" :showDialog="showDialog" :title="title" :width="width">
            <template v-slot:content>
                <div class="dialog_content">

                    <!-- 间隔线 -->
                    <el-divider></el-divider>

                    <!-- 教室名称 -->
                    <div class="margin_top_30 margin_left_60">
                        <span><span class="tv_red">*</span>教室名称：</span>
                        <!-- 教室名称 -->
                        <el-input maxlength="10" clearable v-model="classRoomName" placeholder="请输入教室名称"></el-input>
                    </div>

                    <!-- 教室编号 -->
                    <div class="margin_top_30 margin_left_60">
                        <span><span class="tv_red">*</span>教室编号：</span>
                        <!-- 教室编号 -->
                        <el-input maxlength="5" clearable v-model="classRoomNum" placeholder="请输入教室编号"></el-input>
                    </div>

                    <!-- 替换按钮  -->
                    <div class='com_dv'>
                        <el-button :loading="showLoading" :class="['com_bt_action','commit_bt',isArrowSearch?'search_bt_active':'search_bt_normal']" @click.stop="doSaveAction">保&nbsp;&nbsp;&nbsp;&nbsp;存</el-button>
                    </div>
                </div>
            </template>
        </CommonDialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            title: "新增教室",

            isCenter: false,

            width: "480px",

            //是否显示loading
            isShowLoading: false,

            //教室名称
            classRoomName: "",

            //教室编号
            classRoomNum: "",

            //确定按钮是否高亮
            isArrowSearch: false,

            //提交状态值
            showLoading: false,
        };
    },
    created() {},
    methods: {
        //关闭的方法
        close() {
            this.$emit("close");
        },

        //确定
        doSaveAction() {
            this.showLoading = true;
            let param = this.getHttpParams();
            param.type = 5;
            param.classroomName = this.classRoomName;
            param.classroomNumber = this.classRoomNum;
            this.$http
                .fetchPost(this.$api.LESSONMANAGER, param)
                .then((res) => {
                    let result = res.data;
                    if (result.state == 200) {
                        //增加成功,关闭
                        this.close();
                        this.showLoading = false;
                        this.$emit("refreshPageData");
                    } else {
                        this.showLoading = false;
                        this.showErrorMsg(res.data.msg);
                    }
                })
                .catch((err) => {
                    this.showLoading = false;
                    this.showErrorMsg("发生异常，新增失败");
                });
        },
    },
    computed: {},
    components: {},
    props: ["showDialog"],
    computed: {
        showState() {
            const { classRoomName, classRoomNum } = this;
            return {
                classRoomName,
                classRoomNum,
            };
        },
    },

    watch: {
        //监听输入的教师名称和教师编号是否有值来同步保存按钮的高亮状态
        showState(val) {
            if (
                !this.isEmpty(val.classRoomName) &&
                !this.isEmpty(val.classRoomNum)
            ) {
                this.isArrowSearch = true;
            } else {
                this.isArrowSearch = false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.add_class_room_dialog {
    .dialog_content {
        height: 240px;
        position: relative;

        .com_dv {
            width: 100%;
            align-items: center;
            justify-content: center;
            display: flex;

            .search_bt_active {
                background: #409eff;
                color: white;
                cursor: pointer;
            }

            .search_bt_normal {
                background: #cfd1d4;
                color: white;
                cursor: not-allowed;
            }

            .commit_bt {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                width: 120px;
                position: absolute;
                bottom: 14px;
                left: 50%;
                transform: translateX(-50%);
                border: none;
            }
        }

        .msg_info {
            color: red;
        }

        ::v-deep .el-input__inner {
            height: 32px;
            line-height: 32px;
            padding-left: 6px;
        }

        ::v-deep .el-input__icon {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        ::v-deep .el-input {
            width: 210px;
        }

        ::v-deep .el-input__suffix {
            right: 0px;
        }
    }
}
</style>
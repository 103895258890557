<template>
    <div class="edit_class_rooms">
        <!-- 视图部分 -->
        <Layout :showLoading="showLoading">

            <template v-slot:content>
                <!-- 上课教室 -->
                <div class="edit_class_rooms_content">
                    <!-- 新增上课教室 -->
                    <div class="has_padding search_bt_active com_bt_action margin_top_14" @click.stop="addClassRoom">
                        新增上课教室
                    </div>

                    <!-- 表格部分 -->
                    <div class="dv_table_view_content">
                        <!-- tableWidth -->
                        <el-table style="width: 98%" border stripe :data="tableData" :max-height="maxHeight">
                            <!-- 教室名称 -->
                            <el-table-column prop="roomName" align="center" label="教室名称" width="200"></el-table-column>

                            <!-- 教室编号 -->
                            <el-table-column prop="roomCode" align="center" label="教室编号" width="200"></el-table-column>

                            <!-- 状态 -->
                            <el-table-column prop="classRoomState" align="center" label="状态" width="200"></el-table-column>

                            <!--  操作 -->
                            <el-table-column align="center" label="操作" min-width="30%">
                                <template slot-scope="scope">
                                    <el-button class="bt_aciton" @click="doUpdateAction(scope.row)" type="text" size="small">修改</el-button>
                                    <el-button class="bt_aciton" @click="doStopAction(scope.row)" type="text" size="small">{{scope.row.state==0?"停用":"开启"}}</el-button>
                                    <el-button class="bt_aciton" @click="doDelAction(scope.row)" type="text" size="small">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>

                    <!-- 新增教室的弹框 -->
                    <AddClassRoom @refreshPageData="refreshPageData" v-if="showAddClassRoomDialog" @close="showAddClassRoomDialog=false" :showDialog="showAddClassRoomDialog"></AddClassRoom>

                    <!-- 编辑教室的弹框 -->
                    <UpdateClassRoom :actionItem="actionItem" @close="showEditClassRoomDialog=false" v-if="showEditClassRoomDialog" :showDialog="showEditClassRoomDialog"></UpdateClassRoom>

                    <!-- 停用教室的弹框 -->
                    <StopClassRoom :actionItem="actionItem" @close="showStopClassRoomDialog=false" v-if="showStopClassRoomDialog" :showDialog="showStopClassRoomDialog"></StopClassRoom>

                    <!-- 删除教室的弹框 -->
                    <DelClassRoom @refreshPageData="refreshPageData" :actionItem="actionItem" @close="showDelClassRoomDialog=false" v-if="showDelClassRoomDialog" :showDialog="showDelClassRoomDialog"></DelClassRoom>

                </div>

            </template>
        </Layout>
    </div>
</template>

<script>
//新增教室
import AddClassRoom from "@/components/dialog/AddClassRoom";

//修改教室
import UpdateClassRoom from "@/components/dialog/UpdateClassRoom";

//停用教室
import StopClassRoom from "@/components/dialog/StopClassRoom";

//删除教室
import DelClassRoom from "@/components/dialog/DelClassRoom";

export default {
    data() {
        return {
            showLoading: -1,

            //表格的最大高度
            maxHeight: 0,

            //是否显示新增教室的弹框
            showAddClassRoomDialog: false,

            //是否显示编辑教室的弹框
            showEditClassRoomDialog: false,

            //是否显示停用教室的弹框
            showStopClassRoomDialog: false,

            //是否显示删除教室的弹框
            showDelClassRoomDialog: false,

            //表格数据
            tableData: [],

            //操作的条目对象
            actionItem: null,
        };
    },
    created() {
        this.initData();

        //请求页面数据
        this.httpData();
    },
    methods: {
        //初始化表格最大高度
        initData() {
            let clientH = document.documentElement.clientHeight;
            this.maxHeight = clientH - 76 - 100;
        },

        //请求数据
        httpData() {
            let param = this.getHttpParams();
            param.type = 6;
            this.$http
                .fetchPost(this.$api.LESSONMANAGER, param)
                .then((res) => {
                    //显示内容视图
                    this.showLoading = 1;
                    let result = res.data;
                    if (result.state == 200) {
                        //取出列表数据
                        let serverData = result.data;
                        //先清空一下数组
                        this.tableData.splice(0, this.tableData.length);
                        if (!this.isEmpty(serverData)) {
                            serverData.forEach((item) => {
                                let state = item.state;
                                //设置状态
                                this.$set(
                                    item,
                                    "classRoomState",
                                    state == 0 ? "使用中" : "已停用"
                                );
                            });
                            //方式一:通过concat函数
                            this.tableData = this.tableData.concat(serverData);
                            //方式二:通过解构
                            // this.tableData = [...serverData];
                        }
                    } else {
                        this.showErrorMsg(this.getMesage(res.data.state));
                    }
                })
                .catch((err) => {
                    this.showLoading = 1;
                    console.log("发生异常");
                });
        },

        //新增上课教室
        addClassRoom() {
            this.showAddClassRoomDialog = true;
        },

        //修改
        doUpdateAction(item) {
            this.actionItem = item;
            this.showEditClassRoomDialog = true;
        },

        //停用
        doStopAction(item) {
            this.actionItem = item;
            this.showStopClassRoomDialog = true;
        },

        //删除
        doDelAction(item) {
            this.actionItem = item;
            this.showDelClassRoomDialog = true;
        },

        //刷新页面数据的方法
        refreshPageData() {
            this.httpData();
        },
    },
    components: {
        AddClassRoom,
        UpdateClassRoom,
        StopClassRoom,
        DelClassRoom,
    },
};
</script>

<style lang="scss" scoped>
.edit_class_rooms {
    margin: 0 14px;
    height: calc(100vh - 78px);
    display: flex;
    flex-direction: column;
    .edit_class_rooms_content {
        display: flex;
        flex-direction: column;
        .has_padding {
            padding: 0 10px;
        }

        //表格内容部分
        .dv_table_view_content {
            flex: 1;
            min-width: 1200px;
            margin: 10px 0;

            .bt_aciton {
                padding: 3px 14px;
                height: 24px;
                margin: 0 10px;
                border: 1px solid #409eff;
            }
        }
    }
}
</style>